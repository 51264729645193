import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Body5,
  Body7,
  DeleteIcon20,
  Heading4,
  Tooltip,
  FeedbackIcon16,
  Spinner
} from '@sefar/design-system'
import dateFns from 'date-and-time'
import { OrderItemEditableField } from './order-item-editable-field'
import { CURRENCY } from '../consts'
import {
  PurchaseRequisitionOrderItem,
  PurchaseRequisitionSupplier
} from '../types'
import { Table, TD, TH, TR } from '../../../my-content/common'
import { useTranslate } from '../../../../hooks/useTranslate'
import { useProductPrice, useSupplierByProductId } from '../../api'
import { AVAILABLE_UNITS } from '../../api/consts'
import Decimal from 'decimal.js'
import { NoteDialog } from './note-dialog'
import { NotePreview } from './note-preview'

type OrderItemsTableProps = {
  orderItems: PurchaseRequisitionOrderItem[]
  isEdit?: boolean
  isAdministratorChangedOrderItems?: boolean
  onChangeItem?: (
    itemId: number,
    fieldKey?: string,
    fieldValue?: string | number,
    setItem?: Partial<PurchaseRequisitionOrderItem>
  ) => void
  onAddItem?: () => void
  onDeleteItem?: (deleteId: number) => void
}

type OrderItemProps = {
  item: PurchaseRequisitionOrderItem
  isEdit?: boolean
  showConfirmedDeliveryDate?: boolean
  onChangeItem?: (
    itemId: number,
    fieldKey?: string,
    fieldValue?: string | number,
    setItem?: Partial<PurchaseRequisitionOrderItem>
  ) => void
  onDeleteItem?: (deleteId: number) => void
}

const unitsOptions = (
  Object.keys(AVAILABLE_UNITS) as Array<keyof typeof AVAILABLE_UNITS>
).map((value) => ({
  label: value,
  value: value
}))

const OrderItem = ({
  item,
  isEdit,
  showConfirmedDeliveryDate,
  onChangeItem,
  onDeleteItem
}: OrderItemProps) => {
  const {
    id,
    productId,
    code,
    itemDescription,
    supplier,
    supplierItemNo,
    quantity,
    unit,
    price,
    currency,
    changedDeliveryDate,
    confirmedDeliveryDate,
    requiredDeliveryDate,
    note
  } = item
  const [openNoteDialog, setOpenNoteDialog] = useState<boolean>(false)
  const { data: productSuppliers } = useSupplierByProductId(code)
  const { data: pricingData, loading: priceLoading } = useProductPrice({
    itemCode: code,
    buyFromBP: item.supplierItemId,
    orderQuantity: quantity,
    currency,
    orderUOM: item.unit
  })
  const { t } = useTranslate()
  const supplierOptions = code
    ? productSuppliers?.[code]?.map((sup: PurchaseRequisitionSupplier) => ({
        label: sup.bpName,
        value: sup.bpName
      }))
    : []
  const deliveryDate =
    !isEdit && showConfirmedDeliveryDate
      ? changedDeliveryDate ?? confirmedDeliveryDate
      : requiredDeliveryDate
  const deliveryDateSubValue =
    showConfirmedDeliveryDate && requiredDeliveryDate
      ? `(Required: ${dateFns.format(new Date(requiredDeliveryDate), 'DD/MM/YYYY')})`
      : undefined

  useEffect(() => {
    if (!onChangeItem) return
    if (pricingData?.bookPrice_value) {
      const price = parseFloat(pricingData?.bookPrice_value).toFixed(2)
      onChangeItem(id, 'price', price)
    } else if (isEdit && code) {
      onChangeItem(id, 'price', undefined)
    }
  }, [pricingData, code])

  useEffect(() => {
    const defaultSup = code ? productSuppliers?.[code]?.[0] : null
    if (code && productSuppliers?.[code] && defaultSup && onChangeItem) {
      onChangeItem(id, undefined, undefined, {
        supplier: defaultSup.bpName,
        supplierItemId: defaultSup.buyFromBP,
        supplierItemNo: defaultSup.buyFromBPItemCode
      })
    }
  }, [productSuppliers, code])

  useEffect(() => {
    if (supplier && code && onChangeItem) {
      const sup = productId
        ? productSuppliers?.[code]?.find(
            (sup: PurchaseRequisitionSupplier) => sup.bpName === supplier
          )
        : {}
      if (sup?.buyFromBP) {
        onChangeItem(id, undefined, undefined, {
          supplierItemId: sup.buyFromBP,
          supplierItemNo: sup.buyFromBPItemCode
        })
      }
    }
  }, [productSuppliers, supplier, code])

  return (
    <>
      <TR
        key={id}
        css={{
          borderBottom: '1px solid $neutralLighten80',
          h: '5rem'
        }}
      >
        <TD css={{ textAlign: 'center' }}>{code}</TD>
        <TD>
          <OrderItemEditableField
            itemId={id}
            fieldKey="itemDescription"
            label="Description"
            value={itemDescription}
            isEdit={!code && !productId && isEdit}
            onChange={onChangeItem}
            maxLength={30}
          />
        </TD>
        <TD>
          <OrderItemEditableField
            itemId={id}
            type="select"
            fieldKey="supplier"
            label="Supplier"
            options={supplierOptions}
            value={supplier}
            isEdit={!code && !productId && isEdit}
            onChange={onChangeItem}
          />
        </TD>
        <TD css={{ width: 80 }}>
          {supplierOptions?.length && productId ? (
            <OrderItemEditableField
              itemId={id}
              fieldKey="supplierItemNo"
              label="Supplier item no"
              value={supplierItemNo}
              isEdit={false}
              onChange={onChangeItem}
            />
          ) : (
            <OrderItemEditableField
              itemId={id}
              fieldKey="supplierItemNo"
              label="Supplier item no"
              value={supplierItemNo}
              isEdit={isEdit}
              onChange={onChangeItem}
            />
          )}
        </TD>
        <TD>
          <OrderItemEditableField
            itemId={id}
            fieldKey="quantity"
            label="Quantity"
            value={quantity}
            isEdit={isEdit}
            onChange={onChangeItem}
            type="number"
          />
        </TD>
        <TD>
          <OrderItemEditableField
            itemId={id}
            fieldKey="unit"
            label="Unit"
            value={unit}
            type="select"
            options={unitsOptions}
            isEdit={isEdit && !code}
            onChange={onChangeItem}
          />
        </TD>
        <TD>
          <OrderItemEditableField
            itemId={id}
            fieldKey="price"
            label="Price"
            value={price}
            loading={isEdit && !!code && priceLoading}
            isEdit={isEdit && !code}
            onChange={onChangeItem}
            inputType="number"
          />
        </TD>
        <TD>
          <OrderItemEditableField
            itemId={id}
            fieldKey="currency"
            label="Currency"
            value={currency}
            isEdit={!productId && isEdit}
            onChange={onChangeItem}
          />
        </TD>
        <TD>
          {price ? (
            new Decimal(parseFloat(price)).mul(quantity).toFixed(2)
          ) : priceLoading ? (
            <Spinner size="small" />
          ) : (
            '0.00'
          )}
        </TD>
        <TD style={{ width: 80 }}>
          <OrderItemEditableField
            itemId={id}
            fieldKey="requiredDeliveryDate"
            label="Required del. date"
            value={deliveryDate}
            subValue={deliveryDateSubValue}
            isEdit={isEdit}
            onChange={onChangeItem}
            type="date"
          />
        </TD>
        <TD>
          {isEdit ? (
            <Tooltip
              text={t('field_purchase_requisition_item_note')}
              withArrow={false}
              contentProps={{ css: { padding: '$1' } }}
            >
              <Box
                css={{
                  d: 'flex',
                  alignItems: 'center',
                  gap: '$1',
                  cursor: 'pointer',
                  '&:hover': {
                    '& svg': {
                      stroke: '$primaryBlue',
                      '& path': {
                        stroke: '$primaryBlue'
                      }
                    }
                  },
                  '& svg': {
                    transition: '$default',
                    '& path': {
                      transition: '$default'
                    }
                  }
                }}
                onClick={() => setOpenNoteDialog(true)}
              >
                <FeedbackIcon16 />
              </Box>
            </Tooltip>
          ) : (
            <NotePreview note={note} />
          )}
        </TD>
        <TD>
          {isEdit && onDeleteItem && (
            <Button
              icon
              noBorder
              variant="transparent"
              css={{
                backgroundColor: 'transparent',
                color: '$primaryRed'
              }}
              onClick={() => onDeleteItem(id)}
            >
              <DeleteIcon20 />
            </Button>
          )}
        </TD>
      </TR>
      {isEdit && (
        <NoteDialog
          open={openNoteDialog}
          setOpen={setOpenNoteDialog}
          initialMessage={note}
          onFeedbackSubmit={(noteText: string) => {
            if (onChangeItem) {
              onChangeItem(id, 'note', noteText)
              setOpenNoteDialog(false)
            }
          }}
        />
      )}
    </>
  )
}

export const OrderItemsTable = ({
  orderItems,
  isEdit = false,
  isAdministratorChangedOrderItems = false,
  onChangeItem,
  onAddItem,
  onDeleteItem
}: OrderItemsTableProps) => {
  const { t } = useTranslate()

  const totalAmount = orderItems
    .map(({ price, quantity }) => new Decimal(price || 0).mul(quantity))
    .reduce((a, b) => a.add(b), new Decimal(0))
    .toFixed(2)
  const isItemConfirmedDeliveryDateAvailable = orderItems.some(
    (orderItem) =>
      orderItem.changedDeliveryDate || orderItem.confirmedDeliveryDate
  )

  return (
    <Box css={{ maxWidth: '100%' }}>
      {isAdministratorChangedOrderItems && (
        <Box
          css={{
            backgroundColor: '$primaryBlueLighten97',
            p: '$3',
            textAlign: 'center'
          }}
        >
          {t('field_purchase_requisition_admin_order_change')}
        </Box>
      )}
      <Box>
        <Table css={{ borderCollapse: 'collapse' }}>
          <thead>
            <TR header>
              <TH css={{ width: 88 }}>
                <Body7>Item</Body7>
              </TH>
              <TH css={{ width: 296 }}>
                <Body7>Item description</Body7>
              </TH>
              <TH css={{ width: 172 }}>
                <Body7>Supplier</Body7>
              </TH>
              <TH css={{ width: 142 }}>
                <Body7>Supplier item no</Body7>
              </TH>
              <TH css={{ width: 124 }}>
                <Body7>Quantity</Body7>
              </TH>
              <TH css={{ width: 100 }}>
                <Body7>Unit</Body7>
              </TH>
              <TH css={{ width: 88 }}>
                <Body7>Price</Body7>
              </TH>
              <TH css={{ width: 88 }}>
                <Body7>Currency</Body7>
              </TH>
              <TH css={{ width: 88 }}>
                <Body7>Line total</Body7>
              </TH>
              <TH css={{ width: 80 }}>
                <Body7>
                  {isItemConfirmedDeliveryDateAvailable
                    ? 'Confirmed del. date'
                    : 'Required del. date'}
                </Body7>
              </TH>
              <TH css={{ width: 38 }} />
              <TH css={{ width: 38 }} />
            </TR>
          </thead>
          <tbody>
            {orderItems.map((item) => (
              <OrderItem
                item={item}
                onChangeItem={onChangeItem}
                onDeleteItem={onDeleteItem}
                isEdit={isEdit}
                showConfirmedDeliveryDate={isItemConfirmedDeliveryDateAvailable}
                key={item.id}
              />
            ))}
          </tbody>
        </Table>
      </Box>
      <Box css={{ mt: '$8', d: 'flex', alignItems: 'center', gap: '$4' }}>
        {isEdit && (
          <Button icon onClick={onAddItem}>
            <Box as="span" css={{ fontSize: 24, lineHeight: 1, mr: '$2' }}>
              +
            </Box>
            {t('field_purchase_order_add_new_custom_item')}
          </Button>
        )}
        <Box css={{ ml: 'auto', d: 'flex', gap: '$3', alignItems: 'flex-end' }}>
          <Body5 fontWeight="bold" css={{ color: '$neutralLighten60' }}>
            {t('field_purchase_order_total_amount')}
          </Body5>
          <Heading4 css={{ color: '$neutralLighten25' }}>
            {totalAmount} {CURRENCY}
          </Heading4>
        </Box>
      </Box>
    </Box>
  )
}
