import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@sefar/design-system'
import { usePurchaseRequisition } from '../api'
import { PurchaseRequisitionStatusType } from '../consts'
import { ApproverBottomBar } from '../create/components/approver-bottom-bar'
import { Heading } from '../create/components/heading'
import { CURRENCY } from '../create/consts'
import { useOrderState } from '../create/hooks/use-order-state'
import { OverviewStep } from '../create/steps/overview'

export const PurchaseRequisitionOverview = () => {
  const [
    isAdministratorChangedOrderItems,
    setIsAdministratorChangedOrderItems
  ] = useState(false)
  const { setDetails, setItems } = useOrderState()
  const { id } = useParams()
  const { data } = usePurchaseRequisition(id)
  const requestApprover = data?.approver

  useEffect(() => {
    if (data) {
      const {
        purchaseRequisitionItems,
        lnPurchaseRequisitionItems,
        ...details
      } = data

      const isLnPurchaseRequisitionItemsAvailable =
        !!lnPurchaseRequisitionItems?.length
      setIsAdministratorChangedOrderItems(isLnPurchaseRequisitionItemsAvailable)

      const rawItems = isLnPurchaseRequisitionItemsAvailable
        ? lnPurchaseRequisitionItems
        : purchaseRequisitionItems

      const preparedItems = rawItems?.map((item: any) => ({
        id: item.id,
        code: item?.product?.code,
        productId: item.productId,
        itemDescription: item.itemDescription,
        currency: item?.product?.currency || CURRENCY,
        price: item.price,
        supplierItemId: item.supplierItemId,
        supplierItemNo: item.supplierItemNo,
        supplier: item.supplier,
        unit: item.unit,
        quantity: item.quantity,
        requiredDeliveryDate: item.requiredDeliveryDate,
        confirmedDeliveryDate: item.confirmedDeliveryDate,
        changedDeliveryDate: item.changedDeliveryDate
      }))
      setItems(preparedItems)
      setDetails(details)
    }
  }, [data])

  return (
    <Box
      css={{
        d: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pb: '$20'
      }}
    >
      <Heading noConfirmation />
      <OverviewStep
        isAdministratorChangedOrderItems={isAdministratorChangedOrderItems}
      />
      {requestApprover &&
        data.status === PurchaseRequisitionStatusType.Created && (
          <ApproverBottomBar requestId={id} requestApprover={requestApprover} />
        )}
    </Box>
  )
}
